<template>
  <div class="grid">
		<div class="col-12">
            <div class="card">
                <Toast/>
                <!-- <Message ref="message" severity="error" :closable="true" v-if="errors.length">{{errors}}</Message> -->
                <Error :errors="errors"/>

                <Fieldset legend="Filter" :toggleable="true" :collapsed="true" class="mb-3">
                    <div class="p-fluid">
                        <div class="formgrid grid">
                            <div class="field col-12 md:col-6 lg:col-3">
                                <label>Medrep</label>
                                <Dropdown v-model="filters.id_mr" :loading="loadingDropdownMedrepFilter" :options="dataDropdownMedrepFilter" optionLabel="label" optionValue="id_mr" placeholder="Pilih Medrep"  :filter="true" :showClear="true" @filter="searchDropdownMedrep($event, 'filter')"/>
                            </div>
                        </div>
                    </div>
                    <Button :loading="loading" label="Filter" icon="pi pi-search" class="p-button-warning mr-2 my-1" @click="getDataTable" />
                </Fieldset>

                <DataTable :value="dataTable" responsiveLayout="scroll" :loading="loading" dataKey="id_lokasi" @sort="onSort($event)">
                    <template #header>
                        <div class="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
                            <div>
                                <!-- Button Excel -->
                            </div>
                            <span class="block mt-2 md:mt-0 p-input-icon-left">
                                <i class="pi pi-search" />
                                <InputText v-model="search" placeholder="Cari..." @keyup.enter="getDataTable" />
                            </span>
                        </div>
                    </template>
                    <Column field="number" header="No." :style="{width:'20px'}" >
                        <template #body="slotProps">
                        {{ slotProps.data.number }}
                        </template>
                    </Column>
                    <Column field="kode_mr" header="Kode MR">
                        <template #body="slotProps">
                            <div v-if="slotProps.data.master_medrep !== null">
                                {{slotProps.data.master_medrep.kode_mr}} 
                            </div>
                        </template>
                    </Column>
                    <Column field="name_mr" header="Nama MR">
                        <template #body="slotProps">
                            <div v-if="slotProps.data.master_medrep !== null">
                                {{slotProps.data.master_medrep.name_mr}} 
                            </div>
                        </template>
                    </Column>
                    <Column field="name_tempat" header="Nama Tempat">
                        <template #body="slotProps">
                            {{slotProps.data.name_tempat}}
                        </template>
                    </Column>
                    <Column field="tempat" header="Tempat">
                        <template #body="slotProps">
                            {{slotProps.data.tempat}}
                        </template>
                    </Column>
                    <Column field="tag_lng" header="Longtitude">
                        <template #body="slotProps">
                            {{slotProps.data.tag_lng}}
                        </template>
                    </Column>
                    <Column field="tag_lat" header="Latitude">
                        <template #body="slotProps">
                            {{slotProps.data.tag_lat}}
                        </template>
                    </Column>
                    <Column field="tag_gps_address" header="Alamat">
                        <template #body="slotProps">
                            {{slotProps.data.tag_gps_address}}
                        </template>
                    </Column>
					<Column header="Aksi" headerStyle="min-width:10rem;">
						<template #body="slotProps">
							<Button icon="pi pi-refresh" class="p-button-rounded p-button-danger mr-2" @click="resetItem(slotProps.data)" v-if="permission.includes('update')"/>
						</template>
					</Column>
                    <template #empty>
                        No data found.
                    </template>
                    <template #loading>
                        Loading data. Please wait.
                    </template>
                    <template #footer>
                        In total there are {{totalItemsCount ? totalItemsCount : 0 }} data.
                    </template>
                </DataTable>

                <Paginator v-model:rows="rows" v-model:first="offset" :totalRecords="totalItemsCount" :rowsPerPageOptions="[10,20,30]">
                    <template>
                        {{totalItemsCount}}
                    </template>
                </Paginator>

				<Dialog v-model:visible="resetItemDialog" :style="{width: '450px'}" header="Konfirmasi" :modal="true" class="p-fluid">
                    <div class="flex align-items-center justify-content-center">
						<i class="pi pi-exclamation-triangle mr-3" style="font-size: 2rem" />
						<span v-if="item">Apakah anda yakin akan mereset GPS dari lokasi <b>{{item.name_tempat}}</b> ini ?</span>
					</div>
					<template #footer>
						<Button label="Tidak" icon="pi pi-times" class="p-button-text" @click="hideDialog"/>
						<Button label="Ya" icon="pi pi-check" class="p-button-text" @click="resetingItem" :loading="loadingReset"/>
					</template>
				</Dialog>
            </div>
      </div>
  </div>
</template>

<script>
import moment from 'moment';
import { mapGetters } from "vuex";
import { numberingDatatable } from '../../utils/helpers';
import Error from '../../components/Error.vue';

export default {
	components: {
		'Error': Error,
	},
    data() {
        return {
            // loading
            loading: false,
            loadingReset: false,
            loadingDropdownMedrepFilter: false,

            // dataDropdown
            dataDropdownMedrepFilter: null,

            // reset
            item: {},
            resetItemDialog: false,

            // filter
            filters: {
                id_mr: null,
            },

            //datatables
            data: null,
            dataTable: null,
            rows: 10,
            search: null,
            offset: null,
            field: null,
            sort: null,
            page: null,
            totalItemsCount: 0,
        }
    },
    mounted() {
        this.getDataTable();
        this.$store.commit('setErrors', {});
        this.$store.commit('setErrorAdd', {});
        this.$store.commit('setErrorEdit', {});
        this.searchDropdownMedrep('');
    },
    watch: {
        offset() {
            this.getDataTable();
        },
        rows() {
            this.getDataTable();
        },
    },
    computed:{
        ...mapGetters(['errors', 'errorEdit', 'errorAdd']),
		...mapGetters("auth", ["permission"]),
    },
    methods: {
        formatDate(value){
            if (value) {
                return moment.utc(value).format('DD-MM-YYYY')
            }
        },
        // DROPDOWN
        searchDropdownMedrep(event, purpose, valueEdit){
            setTimeout(() => {

                if(valueEdit){
                    this.$refs.dmdrp.filterValue = valueEdit;
                }

                if(purpose == "filter"){
                    this.loadingDropdownMedrepFilter = true;
                }

                this.axios({
                    method: 'GET',
                    url: process.env.VUE_APP_ROOT_API + 'web/select2/master-medrep',
                    params: {
                        "search" : valueEdit ? valueEdit : event.value,
                    }
                })
                .then(res => {

                    if(purpose == "filter"){
                        this.dataDropdownMedrepFilter = res.data.data;
                        this.loadingDropdownMedrepFilter = false;
                    }else if(purpose == null){
                        this.dataDropdownMedrepFilter = res.data.data;
                    }
                })
                .catch((err) => {
                    console.log(err);
                });
            }, 250);
        },
        // RESET
		resetItem(item) {
			this.item = item;
			this.resetItemDialog = true;
		},
		hideDialog() {
			this.resetItemDialog = false;
			this.submitted = false;
		},
		resetingItem() {
            this.loadingReset = true;

            this.axios({
                method: 'POST',
                url: process.env.VUE_APP_ROOT_API + 'web/reset-gps/update',
                data: {
                    "id_lokasi" : this.item.id_lokasi,
                },
            })
            .then((res) => {
                this.loadingReset = false;
                if (res) {
                    this.$toast.add({ severity: 'success', summary: 'Successful', detail: 'Data Berhasil Direset', life: 3000,});
                    this.$store.commit('setErrors', {});
                    this.$store.commit('setErrorEdit', {});
                    this.item = {};
                    this.resetItemDialog = false;
                    this.getDataTable();
                }
            })
            .catch((err) => {
                console.log(err);
                this.loadingReset = false;
                this.$store.commit('setErrorEdit', err.response.data.data);
                this.$toast.add({ severity: 'error', summary: 'Failed', detail: 'Data Gagal Direset', life: 3000,});
            });
		},
        // DATATABLE
        getDataTable(){
          
            this.loading=true;
            this.page = (this.offset / this.rows) + 1;

            this.axios({
                method: 'GET',
                url: process.env.VUE_APP_ROOT_API + 'web/reset-gps',
                params: {
                    "search" : this.search,
                    "per_page" : this.rows,
                    "page" : this.page,
                    "order_by" : this.field,
                    "sort_by" : this.sort,
                    "id_mr" : this.filters.id_mr,
                }
            })
            .then(res => {
              this.data = res.data.data.data;
              this.dataTable = numberingDatatable(res.data.data.data, this.page, this.rows);
              this.totalItemsCount = res.data.data.total;
              this.rows = parseInt(res.data.data.per_page);
              this.loading=false;
            })
            .catch((err) => {
                console.log(err);
            });
         
        },
        onSort(event) {
            this.field = event.sortField;
            this.sort = event.sortOrder == '1' ? 'ASC' : 'DESC';
            this.getDataTable();
        },
    }
}
</script>